export const environment = {
  production: false,
  staging: false,
  hashEmailSmsAuthorization: "960d5b108784b4f20f38d79dcacb1fda",
  hashAuthorization: "c9aE5c0809BCe6c7598Fd6D174dB5A5a",
  urlApiBase: "https://clusterdev.tolife.app/",
  urlApiUser: "https://api.clusterdev.tolife.app/tolifecloud-api-user/",
  urlApiUbsHealthPromotion: "https://api.clusterdev.tolife.app/tolifecloud-api-ubs-health-promotion/",
  //urlApiUser: "http://localhost:49543/",
  urlApiAuth: "https://api.clusterdev.tolife.app/tolifecloud-api-auth/",
  urlApiSrvEmail: "https://api.clusterdev.tolife.app/tolifecloud-api-srvemail/",
  urlApiFlow: "https://api.clusterdev.tolife.app/tolifecloud-api-flow/",
  urlApiTotem: "https://api.clusterdev.tolife.app/tolifecloud-api-totem/",
  urlApiPanel: "https://api.clusterdev.tolife.app/tolifecloud-api-panel/",
  urlWebsocket: "wss://api.clusterdev.tolife.app/",
  urlApiOrchestratorTotem: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-totem/",
  urlApiOrchestratorQueue: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-queue/",
  urlApiOrchestratorPanel: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-panel/",
  urlApiQueue: "https://api.clusterdev.tolife.app/tolifecloud-api-queue/",
  urlApiFrontDesk: "https://api.clusterdev.tolife.app/tolifecloud-api-frontdesk/",
  urlApiPharmacy: "https://api.clusterdev.tolife.app/tolifecloud-api-pharmacy/",
  urlApiMedicalRecord: "https://api.clusterdev.tolife.app/tolifecloud-api-medical-record/",
  urlApiSADT: "https://api.clusterdev.tolife.app/tolifecloud-api-sadt/",
  urlApiTotemPrinter: "http://localhost:49577/",
  urlApiOrchestratorPatient: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-patient/",
  urlApiOrchestratorSADT: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-sadt/",
  urlApiRiskClassification: "https://api.clusterdev.tolife.app/tolifecloud-api-risk-classification/",
  urlApiSensors: "http://localhost:5001/",
  urlApiIntegration: "https://api.clusterdev.tolife.app/tolifecloud-api-integration/",
  urlApiOrchestratorIntegration: "https://api.clusterdev.tolife.app/integration/",
  urlApiIntegrationEsaudeSP: "https://api.clusterdev.tolife.app/tolifecloud-api-integration-esaudesp/",
  urlApiAudit: "https://api.clusterdev.tolife.app/tolifecloud-api-audit/",
  urlApiOrchestratorAudit: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-audit/",
  urlApiBed: "https://api.clusterdev.tolife.app/tolifecloud-api-bed/",
  urlWorkerSyncMeasurer: "http://localhost:7000/",
  urlApiSyncMeasurer: "https://api.clusterdev.tolife.app/tolifecloud-api-sync-measurer/",
  urlBaseUIDevice: "https://device.clusterdev.tolife.app/",
  urlBaseDevice: "http://localhost:9090/",
  locale: 'pt-BR',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
